// Optional but if used means within our providers we can
import { CognitoUser } from 'amazon-cognito-identity-js'

type PageRequest<T> = T & {
  size?: number
  page?: number
  sort?: string
}

export type RequestUrl = {
  id: string
  url: string
}

export type ProjectPermission = {
  id: string
  name: string
  role: ProjectRole
}

export type UserPermissions = {
  id: string
  firstName: string
  lastName: string
  role: OrganisationRole
  projects: ProjectPermission[]
}

type PageResponse<T> = {
  [extraProps: string]: any
  content: T[]
  pageable: string
  totalPages: number
  numberOfElements: number
  totalElements: number
  last: boolean
  sort: {
    unsorted: boolean
    sorted: boolean
    empty: boolean
  }
  first: boolean
  size: number
  number: number
  empty: boolean
}

export enum ProjectSortAttr {
  NAME='t.name',
  NAME_DESC='t.name,desc',
  STAGE = 'stage,desc',
  TYPE = 'type',
  TYPE_DESC = 'type,desc',
  STATUS = 'status',
  STATUS_DESC = 'status,desc',
  MODIFIED_ON = '', // use default (modified on - reverse cronological)
  MODIFIED_ON_DESC='modifiedOn,desc',
  ANONYMOUS= 'assessorId,desc',
  ANONYMOUS_ASC= 'assessorId'
}

export interface Project {
  id: string
  role: ProjectRole
  name: string
}

export interface Organisation {
  id: string
  name: string
  status: string
  logoUrl: string
}

export enum ProjectRole {
  VIEWER = 'VIEWER',
  ASSESSOR = 'ASSESSOR',
  NONE = 'NONE',
}

export enum OrganisationRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export interface Profile {
  id: string
  emailAddress: string
  firstName: string
  lastName: string
  role: string
  organisations: {
    id: string
    name: string
    role: OrganisationRole
    logoUrl: string
    templates: {
      label: string
      name: string
      templateId: string
      stages: { label: string; value: string }[]
    }[]
  }[]
  projects: {
    id: string
    name: string
    role: ProjectRole
  }[]
  token?: string
}

export interface OrganisationResponse {
  id: string
  organisation: Organisation
  name: string
  number: string
  managerName: string
  stage: string
  sponsorName: string
  budget: number
  benefits: [
    {
      isPrimary: boolean
      value: ProjectBenefitValue
    },
  ]
  startDate: string
  initialForecastEndDate: string
}

export interface CriticalAction {
  question: string
  actions: string
  rag: RAG
  score: number
  successFactor: SuccessFactor
  importance: IMPORTANCE
}

export interface OnesToWatch {
  question: string
  actions: string
  rag: RAG
  score: number
  successFactor: SuccessFactor
  importance: IMPORTANCE
}

export enum IMPORTANCE {
  VERY_HIGH = 'VERY_HIGH',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  VERY_LOW = 'VERY_LOW',
}

export interface ReportSuccessFactor {
  label: string
  rag: RAG
  score: number
  notes?: string
  importance?: IMPORTANCE
}

export interface RagAssessment {
  successFactors: ReportSuccessFactor[]
  label: string
  rag: RAG
  score: number
}
export interface AssessmentReport {
  id: string
  project: Project
  organisation: Organisation
  assessorFirstname: string
  assessorLastname: string
  createdOn: string
  modifiedOn: string
  status: ASSESSMENT_STATUS
  stage: PROJECT_STAGE
  report: {
    summary: string
    criticalActions: CriticalAction[]
    onesToWatch: OnesToWatch[]
    ragAssessment: RagAssessment[]
    howMainstayCanHelp: string
  }
}

export enum PROJECT_STAGE {
  DEFINITION = 'DEFINITION',
  PLAN = 'PLAN',
  DELIVERY = 'DELIVERY',
}

export enum SECTOR {
  AGRICULTURE = 'AGRICULTURE',
  CONSTRUCTION_AND_MATERIALS = 'CONSTRUCTION_AND_MATERIALS',
  CONSUMER_GOODS_AND_SERVICES = 'CONSUMER_GOODS_AND_SERVICES',
  ENERGY = 'ENERGY',
  FINANCIALS = 'FINANCIALS',
  FOOD_DRINKS_AND_TOBACCO = 'FOOD_DRINKS_AND_TOBACCO',
  GOVERNMENT_AND_PUBLIC_SECTOR = 'GOVERNMENT_AND_PUBLIC_SECTOR',
  HEALTHCARE = 'HEALTHCARE',
  MANUFACTURING = 'MANUFACTURING',
  MEDIA = 'MEDIA',
  MINING = 'MINING',
  REAL_ESTATE = 'REAL_ESTATE',
  RETAIL = 'RETAIL',
  SERVICES = 'SERVICES',
  SOFTWARE_AND_COMPUTER_SERVICES = 'SOFTWARE_AND_COMPUTER_SERVICES',
  TECHNOLOGY_HARDWARE_AND_EQUIPMENT = 'TECHNOLOGY_HARDWARE_AND_EQUIPMENT',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  TRANSPORTATION = 'TRANSPORTATION',
  TRAVEL_AND_LEISURE = 'TRAVEL_AND_LEISURE',
  UTILITIES = 'UTILITIES',
}

export enum DELIVERY_METHODOLOGY {
  WATERFALL = 'WATERFALL',
  AGILE = 'AGILE',
  HYBRID = 'HYBRID',
  V_MODEL = 'V_MODEL',
  LEAN_SIX_SIGMA = 'LEAN_SIX_SIGMA',
  OTHER = 'OTHER',
}
export enum PROJECT_CATEGORY {
  BUSINESS_TRANSFORMATION = 'BUSINESS_TRANSFORMATION',
  DIGITAL = 'DIGITAL',
  OBSELESCENCE = 'OBSELESCENCE',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  REGULATORY = 'REGULATORY',
}

export enum ProjectBenefitValue {
  COST_AVOIDANCE = 'COST_AVOIDANCE',
  MORE_MONEY = 'MORE_MONEY',
  NEW_MONEY = 'NEW_MONEY',
  STRATEGIC_ALIGNMENT = 'STRATEGIC_ALIGNMENT',
  COMPETITIVE_ADVANTAGE = 'COMPETITIVE_ADVANTAGE',
  COMPETITIVE_RESPONSE = 'COMPETITIVE_RESPONSE',
  MANAGEMENT_INFORMATION = 'MANAGEMENT_INFORMATION',
}

export enum QUESTION_TYPE {
  SLIDER = 'SLIDER',
  RADIO = 'RADIO',
  TICK_LIST = 'TICK_LIST',
}
export enum RAG {
  GREEN = 'GREEN',
  AMBER = 'AMBER',
  RED = 'RED',
  GREEN_AMBER = 'GREEN_AMBER',
  BLANK = 'BLANK',
  AMBER_RED = 'AMBER_RED',
}

export type RAGStatus = {
  status: RAG
  section: string
}

export enum ASSESSMENT_STATUS {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export interface Answer {
  label: string
  selected: boolean
  score: number
  action?: string
  helpText?:string
}

export interface Question {
  answers: Answer[]
  label: string
  importance: string
  type: QUESTION_TYPE
  order?: number
  invalid?: boolean
  helpText?:string
  isFreeText?:boolean,
  freeText?:string
}


export interface Group {
  questions: Question[]
  label?: string
  order?: number
  invalid?: boolean
}

export interface RagStatusCondition {
  question: string
  ragStatuses: RAG[]
}
export interface Note {
  isAllOtherConditions: boolean
  ragStatusConditions: RagStatusCondition[]
  note: string
  helpText?:string
}

export interface SuccessFactor {
  groups: Group[]
  label?: string
  order?: number
  notes: Note[]
}

export interface AssessmentSection {
  successFactors: SuccessFactor[]
  label?: string
  order?: number
}

export interface Assessment {
  id: string
  projectId: string
  stage: PROJECT_STAGE
  assessorFirstname: string
  assessorLastname: string
  status: ASSESSMENT_STATUS
  body: AssessmentSection[]
  createdOn: string
  modifiedOn: string
}

export interface Project {
  id: string
  organisation: Organisation
  name: string
  number: string
  managerName: string
  stage: PROJECT_STAGE
  sponsorName: string
  budget: number
  benefits: {
    isPrimary: boolean
    value: ProjectBenefitValue
  }[]
  startDate: string
  initialForecastEndDate: string
  projectCategory: string
  deliveryMethodology: string
}

export enum ASSESSMENT_TYPE {
  HEALTH_CHECK = 'Health Check',
}
export interface AssessmentProgress {
  isComplete: boolean
  erroredSuccessFactors: boolean[]
}
export interface AssessmentSummaryType {
  id: string
  projectId: string
  stage: PROJECT_STAGE
  assessorId: string
  ragStatus: RAGStatus[]
  type: ASSESSMENT_TYPE
  assessorFullname: string
  status: ASSESSMENT_STATUS
  createdOn: string
  modifiedOn: string
}

export enum TOKEN_AUDIENCE {
  FORGOT_PASSWORD_AUDIENCE = 'forgot-password',
  CHANGE_PASSWORD_AUDIENCE = 'change-password',
}

type CreateProject = {
  name?: string
  number?: string
  managerName?: string
  stage?: PROJECT_STAGE
  sponsorName?: string
  budget?: number
  benefits?: {
    isPrimary?: boolean
    value?: ProjectBenefitValue
  }[]
  startDate?: string
  initialForecastEndDate?: string
  projectCategory: string
  deliveryMethodology: string
}

export interface RequestTypes {
  getUploadUrl?: {
    name: string
    contentType?: string
  }
  login?: {
    [extraProps: string]: any
  }
  logout?: {
    [extraProps: string]: any
  }
  forgotPassword?: {
    email: string
    url: string
  }
  resetPassword: {
    token: string
    confirmPassword: string
    password: string
    tokenAudience: TOKEN_AUDIENCE
  }
  changePassword: {
    [extraProps: string]: any
  }
  getProfile?: {
    [extraProps: string]: any
  }
  updateProfile?: {}
  register?: {
    [extraProps: string]: any
  }
  getProject?: {
    id: string
  }
  updateProject?: CreateProject & {
    id: string
  }
  createAssessment?: {
    projectId: string
    stage: string
    templateId: string
  }
  getAssessment?: {
    id: string
  }
  getAssessmentSummaries?: PageRequest<{
    projectId: string
  }>
  updateAssessment?: {
    id: string
  } & Assessment
  createOrganisation?: {
    locationOfHQ: string
    sector: string
    name: string
    logoMediaKey?: string
    imageFile?: File
  }
  getOrganisation?: {
    id: string
  }
  updateOrganisation?: {
    id: string
  } & Assessment
  completeAssessment?: {
    id: string
  } & Assessment
  setAssessmentSelectedAnswers?: Partial<Assessment>
  getAssessmentReport?: {
    id: string
  }
  downloadAssessmentReport?: {
    id: string
    name: string
    
  }
  setAssessmentProgress?: {
    id: string
    progress: AssessmentProgress[]
  }
  setActiveSuccessFactor?: number
  confirmEmail?: {
    userId: string
    code: string
  }
  createConfirmSignin?: {
    authCode: string
  }
  getUser?: {
    id: string
  }
  createUser?: {
    emailAddress: string
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
  }
  updateUser?: {
    id: string
    firstName: string
    lastName: string
    role: string
  }
  deleteUser?: {
    id: string
  }

  createUploadFile?: {
    file: File | Blob
    prefix?: string
  }
  createProject?: CreateProject & {
    orgId: string
  }
  getUsers?: {
    orgId: string
    pageSize: number
  }
  updateUserOrganisationPermissions?: {
    user: string
    role: OrganisationRole
    orgId: string
  }
  getUserProjectPermissions?: {
    orgId: string
    user: string
  }
  updateUserProjectPermissions?: {
    role: ProjectRole
    user: string
    projectId: string
    linkUrl: string
  }
  createOrganisationInvite?: {
    emailAddress: string
    registerUrl: string
    loginUrl: string
    role: OrganisationRole
  }
  createProjectInvite?: {
    emailAddress: string
    projectId: string
    registerUrl: string
    loginUrl: string
    role: ProjectRole
  }
    messagetestMessagetest?: {
    [extraProps: string]: any;
  };
  checkIsadmin?: {
    [extraProps: string]: any;
  };
  // END OF REQUEST_TYPES
}

export type ImageFile = {
  cropRect: {
    y: number
    height: number
    width: number
    x: number
  }
  modificationDate: string
  width: number
  size: number
  mime: string
  data: string
  height: number
  path: string
}

export interface AppState {
  [extraProps: string]: any // Means that extra props are fine
  userLoading?: boolean
  userError?: string
  theme?: {}
  user?: {
    firstName: string
    lastName: string
    [extraProps: string]: any
  }
  profileLoading?: boolean
  profileError?: string
  profile?: Profile
  projectLoading?: boolean
  projectSaving?: boolean
  projectError?: string
  project?: Record<string, Project>
  assessmentLoading?: boolean
  assessmentSaving?: boolean
  assessmentError?: string
  organisation?: OrganisationResponse
  organisationLoading?: boolean
  organisationSaving?: boolean
  organisationError?: string
  assessment?: Record<string, Assessment>
  assessmentSummariesLoading?: boolean
  assessmentSummariesSaving?: boolean
  assessmentSummariesError?: string
  assessmentSummaries?: Record<string, AssessmentSummaryType[]>
  assessmentSelectedAnswers?: Record<string, Assessment>
  assessmentReportLoading?: boolean
  assessmentReportSaving?: boolean
  assessmentReportError?: string
  assessmentReport?: AssessmentReport[]
  assessmentProgress?: Record<string, AssessmentProgress[]>
  activeSuccessFactor?: number
  confirmSigninLoading?: boolean
  confirmSigninSaving?: boolean
  confirmSigninError?: string
  confirmSignin?: CognitoUser
  uploadFileLoading?: boolean
  uploadFileSaving?: boolean
  uploadFileError?: string
  uploadFile?: {
    [extraProps: string]: any
  }
  usersLoading?: boolean
  usersSaving?: boolean
  usersError?: string
  users?: Record<
    string,
    PageResponse<Omit<Profile, 'organisations' | 'projects'>>
  >
  userOrganisationPermissionsLoading?: boolean
  userOrganisationPermissionsSaving?: boolean
  userOrganisationPermissionsError?: string
  userOrganisationPermissions?: {}
  userProjectPermissionsLoading?: boolean
  userProjectPermissionsSaving?: boolean
  userProjectPermissionsError?: string
  userProjectPermissions?: Record<string, UserPermissions>
  organisationInviteLoading?: boolean
  organisationInviteSaving?: boolean
  organisationInviteError?: string
  organisationInvite?: {
    [extraProps: string]: any
  }
  projectInviteLoading?: boolean
  projectInviteSaving?: boolean
  projectInviteError?: string
  projectInvite?: {
    [extraProps: string]: any
  }
  messagetest?: {
    [extraProps: string]: any;
  },
  isadminLoading?: boolean;
  isadminSaving?: boolean;
  isadminError?: string;
  isadmin?: {
    [extraProps: string]: any;
  },
  // END OF STATE_TYPES
}